import React, { useState } from 'react'
import { Checkbox, Col, Divider, InputNumber, Row, Switch } from 'antd'
import { pick } from 'lodash/fp'
import { styled } from 'styled-components'
import { connect } from 'react-redux'

import { DELIVERY_METHODS, DELIVERY_METHOD_CHOICES } from '../../constants'
import { getAuthUser } from '../../data/auth/selectors'
import { updateUserSettings } from '../../data/auth/actions'
import TelegramBotUrl from '../../ui/telegram-bot.url'
import { LargeButton } from '../../ui/button'

import SearchDetails from './search-details'

const phoneHelpText = 'This phone will be used to sent notification'

export const StyledLabel = styled.p`
  font-weight: bold;
  margin: 15px 0 5px;
  font-size: 14px;
`

const SwitchWrap = styled.div`
  margin-top: 15px;
  span {
    display: inline-block;
    margin-left: 10px;
  }
`

const SettingSearch = (props) => {
  const { user, onSubmit } = props

  const initialValues = pick([
    'min_block_duration',
    'max_block_duration',
    'refresh_rate',
    'sleeping_time',
    'accepted_offer_noti',
    'captcha_noti',
    'missed_offer_noti',
    'send_via',
    'phone_number',
    'timezone_id',
    'search_pattern',
    'search_time',
    'total_search_time',
    'stop_when_captcha',
    'stop_when_accept',
    'stop_when_missed',
    'solve_captcha_automatically',
    'max_search_minutes'
  ])(user)

  const [form, setForm] = useState(initialValues)

  const {
    min_block_duration,
    max_block_duration,
    refresh_rate,
    accepted_offer_noti,
    captcha_noti,
    missed_offer_noti,
    send_via,
    stop_when_captcha,
    stop_when_accept,
    stop_when_missed,
    solve_captcha_automatically
  } = form

  const handleChange = (key, value) => setForm({ ...form, [key]: value })

  const handleSubmit = () => onSubmit(form)

  // const smsEnabled = send_via.includes(DELIVERY_METHODS.SMS)
  const telegramEnabled = send_via.includes(DELIVERY_METHODS.TELEGRAM)

  return (
    <Row>
      <Col xs={24} md={10}>
        <SwitchWrap>
          <Switch
            checked={solve_captcha_automatically}
            onChange={(checked) => handleChange('solve_captcha_automatically', checked)}
          />
          <span>Solve captcha automatically</span>
        </SwitchWrap>

        <Divider />

        <SwitchWrap>
          <Switch checked={stop_when_captcha} onChange={(checked) => handleChange('stop_when_captcha', checked)} />
          <span>STOP when getting Captcha</span>
        </SwitchWrap>

        <SwitchWrap>
          <Switch checked={stop_when_missed} onChange={(checked) => handleChange('stop_when_missed', checked)} />
          <span>STOP after Offer Missed</span>
        </SwitchWrap>

        <SwitchWrap>
          <Switch checked={stop_when_accept} onChange={(checked) => handleChange('stop_when_accept', checked)} />
          <span>STOP after Offer Acceptance</span>
        </SwitchWrap>

        <StyledLabel>Refresh Rate (Seconds)</StyledLabel>
        <InputNumber
          value={refresh_rate}
          onChange={(value) => handleChange('refresh_rate', value)}
          style={{ width: '100%' }}
        />

        <StyledLabel>Block Duration (Hours)</StyledLabel>
        <div>
          <span style={{ display: 'inline-block', marginRight: '10px' }}>Min:</span>
          <InputNumber value={min_block_duration} onChange={(value) => handleChange('min_block_duration', value)} />
          <span style={{ display: 'inline-block', marginLeft: '20px', marginRight: '10px' }}>Max:</span>
          <InputNumber value={max_block_duration} onChange={(value) => handleChange('max_block_duration', value)} />
        </div>

        <SearchDetails form={form} handleChange={handleChange} />

        <Divider />
        <h2 style={{ fontWeight: 'bold' }}>Notification</h2>

        <SwitchWrap>
          <Switch checked={accepted_offer_noti} onChange={(checked) => handleChange('accepted_offer_noti', checked)} />
          <span>Accepted Offer Notification </span>
        </SwitchWrap>
        <SwitchWrap>
          <Switch checked={captcha_noti} onChange={(checked) => handleChange('captcha_noti', checked)} />
          <span>Captcha Notification </span>
        </SwitchWrap>
        <SwitchWrap>
          <Switch checked={missed_offer_noti} onChange={(checked) => handleChange('missed_offer_noti', checked)} />
          <span>Missed Offer Notification </span>
        </SwitchWrap>

        <StyledLabel>Delivery Method</StyledLabel>
        <Checkbox.Group
          options={DELIVERY_METHOD_CHOICES}
          onChange={(checkedValues) => handleChange('send_via', checkedValues)}
          value={send_via}
        />

        <TelegramBotUrl show={telegramEnabled} />
        {/* <SMSNotAvailableWarning show={smsEnabled} /> */}

        {/* <div>
          <Tooltip placement="right" title={phoneHelpText}>
            <StyledLabel style={{display: 'inline-block'}}>Phone Number</StyledLabel>
          </Tooltip>
        </div>
        <Input
          disabled={!smsEnabled}
          value={phone_number}
          placeholder='555-555-5555'
          onChange={(e) => handleChange("phone_number", e.target.value)}
          style={{ width: "100%" }}
        /> */}

        <Divider />

        <LargeButton style={{ marginTop: '20px', padding: '0 50px' }} type='primary' onClick={handleSubmit}>
          Save
        </LargeButton>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  const user = getAuthUser(state)

  return {
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(updateUserSettings(data, { feedbackToGeneral: 'Saved' }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingSearch)
