import React, { useState } from 'react'
import {
  DollarOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
  LogoutOutlined,
  HistoryOutlined,
  ClockCircleOutlined,
  ScheduleOutlined,
  AreaChartOutlined,
  DesktopOutlined,
  AlertOutlined,
  ContainerOutlined,
  CalendarOutlined,
  RocketOutlined
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Dropdown,
  Menu,
  Layout,
  theme,
  Tag,
  Drawer
} from 'antd'
import getOr from 'lodash/fp/getOr'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import { styled } from 'styled-components'

import { REGIONS, REGIONS_DISPLAY, URLS } from '../constants'
import { COLORS, media } from '../constants/styles'
import withAuthMonitor from '../modules/auth/with-auth-monitor'
import AuthRefresh from '../modules/auth/auth-refresh'
import DashboardContainer from '../modules/dashboard/dashboard-container'
import SettingsContainer from '../modules/settings/settings-container'
import HistoryContainer from '../modules/history/history-container'
import Poller from '../modules/poller'
import { getAuthUser } from '../data/auth/selectors'
import { listOffers } from '../data/offer/actions'
import { getUserSleepingTime } from '../data/auth/actions'
import SubscriptionContainer from '../modules/subscription/subscription-container'
import RecentContainer from '../modules/recent/recent-container'
import ScheduledOffersContainer from '../modules/scheduled-offers/scheduled-offers-container'
import MonitorContainer from '../modules/monitor/monitor-container'
import LogsContainer from '../modules/logs/logs-container'
import InstantOfferContainer from '../modules/instant-offer/instant-offer-container'
import DashboardHeaderTitle from './dashboard-header-title'
import ManageSubscription from '../modules/subscription/manage-subscription'
import BotScheduleContainer from '../modules/bot-schedule/bot-schedule-container'

const { Header, Sider, Content } = Layout

const DRAWER_HEADER_HEIGHT = 150
const HEADER_HEIGHT = 100
const HEADER_HEGIGH_ALT = 128

const LayoutWrap = styled.div`
  .ant-layout-content {
    margin: 0 !important;
  }

  .ant-space {
    display: flex;
    margin-top: 1rem;
    text-align: center;
  }

  .ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 50px !important;
    left: 10px !important;
  }
`

const StyledButton = styled(Button)`
  background: transparent;
  color: white;
  transform: scale(2);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    background: ${COLORS.GRAY} !important;
  }
`

const DrawerHeader = styled.div`
  height: ${DRAWER_HEADER_HEIGHT}px;
  background: ${COLORS.ORANGE_FLEX};
  color: ${COLORS.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const StyledBrand = styled.div`
  font-weight: 600;
  font-size: 24px;
  font-family: ClashDisplay, sans-serif;
`

const DashboardLayout = (props) => {
  const { isUserSupport, onRefresh, user } = props

  const [collapsed, setCollapsed] = useState(true)

  const handleOpen = () => setCollapsed(false)
  const onClose = () => setCollapsed(true)

  const navigate = useNavigate()
  const location = useLocation()

  const isRunning = getOr(false, 'is_running')(user)
  const region = getOr(null, 'region')(user)

  const instantOffersActive = region === REGIONS.NA

  const pathname = getOr('/', 'pathname')(location)

  const activeSelector = () => {
    const selector = pathname.replace('/', '')

    if (!selector.trim()) {
      return 'dashboard'
    }

    return selector
  }

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  const useAltLayout = useMediaQuery({ query: '(max-width: 768px)' })

  const getMenuItems = () => {
    const menuItems = [
      {
        key: 'dashboard',
        icon: <HomeOutlined />,
        label: 'Dashboard',
        onClick: () => {
          onClose()
          navigate(URLS.DASHBOARD)
        }
      }
    ]

    if (instantOffersActive) {
      menuItems.push({
        key: 'instant-offer',
        icon: <AlertOutlined />,
        label: (
          <span>
            Instant Offers <Tag color="green">Free</Tag>
          </span>
        ),
        onClick: () => {
          onClose()
          navigate(URLS.INSTANT_OFFER)
        }
      })
    }

    menuItems.push(
      ...[
        {
          key: 'history',
          icon: <ClockCircleOutlined />,
          label: 'History',
          onClick: () => {
            onClose()
            navigate(URLS.HISTORY)
          }
        },
        {
          key: 'recent',
          icon: <ContainerOutlined />,
          label: 'Recent',
          onClick: () => {
            onClose()
            navigate(URLS.RECENT)
          }
        }
      ]
    )

    menuItems.push({
      key: 'scheduled-offers',
      icon: <ScheduleOutlined />,
      label: 'Selfie Bypass',
      onClick: () => {
        onClose()
        navigate(URLS.SCHEDULED_OFFERS)
      }
    })

    menuItems.push({
      key: 'logs',
      icon: <DesktopOutlined />,
      label: 'Logs',
      onClick: () => {
        onClose()
        navigate(URLS.LOGS)
      }
    })

    menuItems.push({
      key: 'bot-schedule',
      icon: <RocketOutlined />,
      label: 'Bot Schedule',
      onClick: () => {
        onClose()
        navigate(URLS.BOT_SCHEDULE)
      }
    })

    menuItems.push({
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
      onClick: () => {
        onClose()
        navigate(URLS.SETTINGS)
      }
    })

    if (isUserSupport) {
      menuItems.push({
        key: 'monitor',
        icon: <AreaChartOutlined />,
        label: 'Monitor',
        onClick: () => {
          onClose()
          navigate(URLS.MONITOR)
        }
      })
    }

    return menuItems
  }

  const getSecondMenuItems = (show) => {
    const menuItems = []

    menuItems.push({
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
      onClick: () => {
        onClose()
        navigate(URLS.AUTH_LOGOUT)
      },
      danger: true
    })

    return menuItems
  }

  return (
    <LayoutWrap>
      <AuthRefresh />
      <Poller
        baseTimeoutMs={5000}
        exponentialBase={1}
        maxRetries={null}
        onPoll={onRefresh}
        isFinished={!isRunning}
      />
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Header
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              height: useAltLayout ? HEADER_HEGIGH_ALT : HEADER_HEIGHT,
              backgroundColor: COLORS.ORANGE_FLEX,
              color: COLORS.WHITE,
              overflow: 'hidden',
              paddingLeft: '20px'
            }}
          >
            {useAltLayout ? (
              <dix>
                <StyledButton
                  style={{ background: 'transparent', color: 'white' }}
                  onClick={handleOpen}
                >
                  <MenuUnfoldOutlined />
                </StyledButton>
                <DashboardHeaderTitle pathname={pathname} />
              </dix>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledBrand>OnlyFlexer</StyledBrand>
                <span style={{ color: COLORS.WHITE, marginLeft: '20px' }}>
                  <Tag
                    style={{
                      color: COLORS.WHITE,
                      marginLeft: '5px',
                      marginRight: '20px'
                    }}
                  >
                    {REGIONS_DISPLAY[region]}
                  </Tag>
                </span>
              </div>
            )}

            <img
              src="./assets/images/car.png"
              alt="car"
              style={{
                height: '80%',
                position: 'absolute',
                top: '50%',
                right: '-50px',
                transform: 'translateY(-50%)'
              }}
            />
          </Header>
          <Layout>
            <Sider
              width={270}
              breakpoint="md"
              collapsedWidth="0"
              style={{
                overflow: 'auto',
                background: COLORS.GRAY
              }}
            >
              <div
                style={{
                  height: useAltLayout
                    ? `calc(100vh - ${HEADER_HEGIGH_ALT}px)`
                    : `calc(100vh - ${HEADER_HEIGHT}px)`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <Menu
                  mode="inline"
                  selectedKeys={activeSelector()}
                  items={getMenuItems()}
                  style={{
                    borderRight: 0,
                    marginTop: '20px',
                    background: COLORS.GRAY
                  }}
                />
                <div>
                  <ManageSubscription handleCloseMenu={onClose} />
                  <Menu
                    mode="inline"
                    selectedKeys={activeSelector()}
                    items={getSecondMenuItems()}
                    style={{
                      borderRight: 0,
                      paddingBottom: '20px',
                      background: COLORS.GRAY
                    }}
                  />
                </div>
              </div>
            </Sider>
            {useAltLayout && (
              <StyledDrawer
                placement="left"
                width={300}
                onClose={onClose}
                open={!collapsed}
                closable={false}
              >
                <DrawerHeader>
                  <StyledBrand>OnlyFlexer</StyledBrand>
                  <div style={{ color: COLORS.WHITE, marginTop: '15px' }}>
                    <Tag
                      style={{
                        color: COLORS.WHITE,
                        marginLeft: '5px',
                        marginRight: '20px'
                      }}
                    >
                      {REGIONS_DISPLAY[region]}
                    </Tag>
                  </div>
                </DrawerHeader>
                <div
                  style={{
                    height: `calc(100vh - ${DRAWER_HEADER_HEIGHT}px)`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <Menu
                    mode="inline"
                    selectedKeys={activeSelector()}
                    items={getMenuItems()}
                    style={{
                      borderRight: 0,
                      marginTop: '20px',
                      background: COLORS.GRAY
                    }}
                  />
                  <div>
                    <ManageSubscription handleCloseMenu={onClose} />
                    <Menu
                      mode="inline"
                      selectedKeys={activeSelector()}
                      items={getSecondMenuItems()}
                      style={{
                        borderRight: 0,
                        paddingBottom: '20px',
                        background: COLORS.GRAY
                      }}
                    />
                  </div>
                </div>
              </StyledDrawer>
            )}
            <Layout>
              <Content
                style={{
                  padding: '20px 30px',
                  minHeight: 280,
                  height: useAltLayout
                    ? `calc(100vh - ${HEADER_HEGIGH_ALT}px)`
                    : `calc(100vh - ${HEADER_HEIGHT}px)`,
                  overflow: 'auto',
                  background: colorBgContainer,
                  borderRadius: '10px'
                }}
              >
                <Routes>
                  <Route
                    path="/"
                    Component={DashboardContainer}
                    key="dashboard"
                  />
                  {instantOffersActive && (
                    <Route
                      path="/instant-offer"
                      Component={InstantOfferContainer}
                      key="instant-offer"
                      exact
                    />
                  )}
                  <Route
                    path="/history"
                    Component={HistoryContainer}
                    key="history"
                    exact
                  />
                  <Route
                    path="/bot-schedule"
                    Component={BotScheduleContainer}
                    key="bot-schedule"
                  />
                  <Route
                    path="/settings"
                    Component={SettingsContainer}
                    key="settings"
                  />
                  <Route
                    path="/subscription"
                    Component={SubscriptionContainer}
                    key="subscriptions"
                  />
                  <Route
                    path="/recent"
                    Component={RecentContainer}
                    key="recent"
                  />
                  <Route
                    path="/scheduled-offers"
                    Component={ScheduledOffersContainer}
                    key="scheduled-offers"
                  />
                  <Route path="/logs" Component={LogsContainer} key="logs" />
                  {isUserSupport && (
                    <Route
                      path="/monitor"
                      Component={MonitorContainer}
                      key="monitor"
                    />
                  )}
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </LayoutWrap>
  )
}

const mapStateToProps = (state) => {
  const user = getAuthUser(state)
  const isUserSupport = getOr(false, 'is_support')(user)
  return {
    isUserSupport,
    user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRefresh: () =>
      dispatch(listOffers()).then(() => dispatch(getUserSleepingTime()))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthMonitor(DashboardLayout))
