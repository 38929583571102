import { Tabs } from 'antd'
import { getOr } from 'lodash/fp'
import React from 'react'

import DisabledWrapper from '../../ui/disabled-wrapper'

import SettingStations from './setting-stations'
import SettingDays from './setting-days'
import SettingSearch from './setting-search'
import SettingRegion from './setting-region'

const SettingsContainer = () => {
  const tabItems = [
    {
      label: 'Stations',
      key: 'station',
      children: (
        <DisabledWrapper>
          <SettingStations/>
        </DisabledWrapper>
      )
    },
    {
      label: 'Days',
      key: 'days',
      children: (
        <DisabledWrapper>
          <SettingDays />
        </DisabledWrapper>
      )
    },
    {
      label: 'Search',
      key: 'search',
      children: (
        <DisabledWrapper>
          <SettingSearch />
        </DisabledWrapper>
      )
    },
    {
      label: 'Region',
      key: 'region',
      children: (
        <DisabledWrapper>
          <SettingRegion />
        </DisabledWrapper>
      )
    }
  ]

  return (
    <div>
      <Tabs defaultActiveKey={getOr(null, '[0].key')(tabItems)} items={tabItems} />
    </div>
  )
}

export default SettingsContainer
